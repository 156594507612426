import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const Button = ({ className, as, children, ...rest }) => {
  const Tag = as === 'a' ? 'a' : as === 'link' ? Link : 'button';
  return (
    <Tag
      {...rest}
      className={`bg-gray-900 text-gray-50 uppercase tracking-wider py-3 px-8 transition-all hover:bg-gray-600 inline-block hover:translate-y-1 focus:bg-gray-600 focus:translate-y-1 outline-none ${className}`}
    >
      {children}
    </Tag>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  as: PropTypes.oneOf(['a', 'link', 'button']),
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  className: '',
  as: 'button',
};

export default Button;
