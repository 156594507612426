import React from 'react';

import Layout from '../components/Layout';
import GatsbySEO from '../components/GatsbySEO';
import Title from '../components/Title';
import Content from '../components/Content';

import Button from '../components/Button';

const ContactPage = () => (
  <Layout>
    <GatsbySEO title="Not Found | Thomas Michael Rutter" />
    <Title>404</Title>
    <Content>
      <p>Sorry, we can't find the page you were looking for. </p>
      <p>
        It may have been moved or deleted, or it may have never existed at all.
      </p>
      <Button as="link" to="/" className="mt-4">
        Home
      </Button>
    </Content>
  </Layout>
);

export default ContactPage;
